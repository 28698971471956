<template>
    <div>
        <b-row>
            <b-col
                lg="3"
                sm="6"
                v-for="(item, key) in series"
                :key="'areaChart'+key"
            >
                <statistic-card-with-area-chart
                    v-if="true"
                    :icon="item.icon"
                    :color="item.colorVariant"
                    :statistic="kFormatter(item.count)"
                    :statistic-title="item.name"
                    :chart-data="[item]"
                    :loading="item.loading"
                    :chart-options="chartOptions"
                />
            </b-col>

            <b-col cols="12">
                <b-card title="Son 10 Sipariş">
                    <b-table
                        ref="refUserListTable"
                        class="position-relative"
                        :items="tableItems"
                        responsive
                        :fields="tableColumns"
                        primary-key="id"
                        show-empty
                        :empty-text="loadingString"
                    >
                        <template #cell(user)="data">
                            <b-media vertical-align="center">
                                <template #aside>
                                    <b-avatar
                                        size="32"
                                        :src="$basePath+data.item.user.fileName"
                                        :text="avatarText(data.item.user.name)+avatarText(data.item.user.surname)"
                                        :variant="`light-primary`"
                                    />
                                </template>
                                <div>{{ data.item.user.name+' '+data.item.user.surname }}</div>
                                <div><a :href="'tel:'+data.item.user.gsm"><small class="text-muted">{{ data.item.user.gsm }}</small></a></div>
                            </b-media>
                        </template>
                        <template #cell(orderDetail)="data">
                            <div><small>Toplam Ürün:</small> <small class="text-muted">{{ data.item.totalQty }}</small></div>
                            <div><small>Toplam Ücret:</small> <small class="text-muted">{{data.item.total}}₺</small></div>
                            <div><small>Sipariş Tarihi:</small> <small class="text-muted">{{ data.item.createdAt | moment("DD.MM.YYYY HH:mm:ss") }}</small></div>
                        </template>
                        <template #cell(status)="data">
                            <b-badge :variant="statusVariants[data.item.status]">{{ statusOptions[data.item.status] }}</b-badge>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import {
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BBadge,
    BTable,
} from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {avatarText} from '@core/utils/filter'

export default {
    name: "Groomer",
    components: {
        BRow,
        BCol,
        BCard,
        BMedia,
        BAvatar,
        BBadge,
        BTable,

        StatisticCardWithAreaChart,
    },
    data(){
        return {
            series: [
                {
                    name: 'Toplam Ürün',
                    icon: 'ListIcon',
                    count: 0,
                    loading: true,
                    colorVariant: 'primary',
                    data: [28, 40, 36, 52, 38, 65, 55],
                    dataType: 1
                },
                {
                    name: 'Toplam Sipariş',
                    icon: 'ShoppingCartIcon',
                    count: 0,
                    loading: true,
                    colorVariant: 'warning',
                    data: [55, 28, 38, 21, 32, 65, 42],
                    dataType: 2
                },
                {
                    name: 'Tamamlanan Sipariş',
                    icon: 'CheckIcon',
                    count: 0,
                    loading: true,
                    colorVariant: 'success',
                    data: [42, 21, 65, 51, 22, 55, 12],
                    dataType: 4
                },
                {
                    name: 'Bekleyen Sipariş',
                    icon: 'ClockIcon',
                    count: 0,
                    loading: true,
                    colorVariant: 'danger',
                    data: [12, 41, 28, 11, 32, 25, 65],
                    dataType: 3
                },
            ],
            chartOptions:{
                tooltip: {
                    enabled: false
                }
            },
            tableColumns: [
                { key: 'id', label:'#' },
                { key: 'user', label:'Kullanıcı' },
                { key: 'orderDetail', label:'Sipariş Detayları' },
                { key: 'status', label:'Durum' },
            ],
            statusOptions: {
                0: 'Ödeme Bekleniyor',
                1: 'Ödendi, Onay Bekleniyor',
                2: 'Ödeme iptal edildi.',
                3: 'Onaylandı, Teslimat Bekleniyor',
                4: 'Sipariş Reddedildi',
                5: 'Kurye Dağıtımında',
                6: 'Teslim Edildi',
                7: 'İade Edildi'
            },
            statusVariants: {
                0: 'info',
                1: 'primary',
                2: 'danger',
                3: 'info',
                4: 'danger',
                5: 'warning',
                6: 'success',
                7: 'danger'
            },
            tableLoading: true,
            tableItems: [],
            avatarText
        }
    },
    created() {
        let t = this
        for(let i = 0; i < t.series.length; i++){
            t.$http.get('/misc/home/count/'+t.series[i].dataType).then(response=>{
                const res = response.data
                if(res.status){
                    t.series[i].count = res.count
                }
            }).catch(err=>{console.log(err)}).then(f=>{
                t.series[i].loading = false
            })
        }

        t.$http.get('order/c/listOrders/1').then(response=>{
            const res = response.data
            const {data} = res

            if(res.status){
                if(data.length){
                    for(let i = 0; i < data.length; i++){
                        let bskt = data[i].basket
                        data[i].totalQty = 0
                        for(let f = 0; f < bskt.length; f++){
                            data[i].totalQty += parseFloat(bskt[f].qty)
                        }
                    }

                    t.tableItems = data
                }
            }else{
                /*if(res.message){
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Hata!',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                            text: res.message
                        },
                    })
                }*/
            }

        }).catch(err=>{console.log(err)}).then(f=>{
            t.tableLoading = false
        })

    },
    computed:{
        loadingString(){
            return this.tableLoading?'Siparişler yükleniyor. Lütfen bekleyin.':'Henüz bir siparişiniz bulunmamaktadır.'
        }
    },
    methods: {
        kFormatter,
    },
}
</script>

<style scoped lang="scss">

</style>
